<template>
<div style="padding: 40px;" v-loading="loading">
    <h3>公钥列表</h3>
    <el-card v-for="obj in public_keys" :key="obj.id" style="margin-bottom: 50px;">
        <h3>{{ obj.title }}</h3>
        <p class="public-key">{{ obj.publickey }}</p>
        <el-button type="danger" size="mini" @click="deleteKey(obj.id)">删除</el-button>
    </el-card>

    <el-divider></el-divider>
    <h3>添加新公钥</h3>
    <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="标题" style="margin-bottom: 40px; margin-top: 30px;">
            <el-input placeholder="请输入公钥标题" v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="公钥" style="margin-bottom: 40px;">
            <el-input type="textarea" placeholder="请粘贴入公钥" style=" font-family: Monospace;"
                v-model="form.pubkey" :autosize="{ minRows: 12, maxRows: 20 }" resize="none"
            ></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm">添加</el-button>
        </el-form-item>
    </el-form>
</div>
</template>
<script>
import api from '../../api';
export default {
    data: () => ({
        public_keys: [],
        form: {
            title: "",
            pubkey: "",
        },
        loading: true
    }),
    mounted() {
        this.loading = true;
        api.getMyPublickey().then(res => {
            if (res.code == 0) {
                this.public_keys = res.data;
            } else {
                this.$message.error(res.message);
            }
            this.loading = false;
        }).catch(err => {
            this.$message.error(err.toString());
            console.error(err);
            this.loading = false;
        });
    },
    methods: {
        submitForm() {
            if (this.form.title != "" && this.form.pubkey != "") {
                this.loading = true;
                api.addMyPublickey(this.form.title, this.form.pubkey).then(res => {
                    if (res.code == 0) {
                        this.public_keys.push( res.data );
                        this.form.title = "";
                        this.form.pubkey = "";
                        this.$message.success("添加公钥成功");
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch(err => {
                    this.$message.error(err.toString());
                    console.error(err);
                    this.loading = false;
                });
            } else {
                this.$message.error("公钥或标题不能为空");
            }
        },
        deleteKey(keyId) {
            this.loading = true;
            api.deleteMyPublickey(keyId).then(res => {
                if (res.code == 0) {
                    let idx = -1;
                    for (let i = 0; i < this.public_keys.length; ++ i) {
                        if (this.public_keys[i].id == keyId) {
                            idx = i;
                            break;
                        }
                    }
                    console.log(idx);
                    if (idx >= 0) {
                        this.public_keys.splice(idx, 1);
                    }
                    this.$message.success("删除公钥成功");
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            }).catch(err => {
                this.$message.error(err.toString());
                console.error(err);
                this.loading = false;
            });
        }
    }
}
</script>
<style scoped>
.public-key {
    font-size: 14px; 
    color: #AAAAAA; 
    line-height: 21px; 
    font-family: Monospace; 
    word-break: break-all;
}
</style>